let YouTubeScriptsIsLoaded = false;

        document.addEventListener("click", (e) => {
            if (e.target.closest(".play-btn")) {
                const video = e.target.closest(".play-btn").dataset.video;
                console.log(video);
                document.querySelector(".player-overlay").style.display = "block";
                runPlayer(video);
            }

            if (e.target.closest(".player-overlay .close-btn") || e.target.classList.contains('player-overlay')) {
                console.log('event')
                document.querySelector(".player-overlay").style.display = "none";
                destroyPlayer();
            }

        });
function runPlayer(videoId) {
    if (!YouTubeScriptsIsLoaded) {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
    let player;

    window.onYouTubeIframeAPIReady = function() {
        player = new YT.Player("player", {
            height: "100%",
            width: "100%",
            videoId: videoId,
            events: {
                onReady: onPlayerReady,
            },
        });
    };

    function onPlayerReady(event) {
        event.target.playVideo();
    }
    if (YouTubeScriptsIsLoaded) {
        window.onYouTubeIframeAPIReady();
    }
}

function destroyPlayer() {
    document.getElementById("player").remove();
    const playerEl = document.createElement("div");
    playerEl.id = "player";
    document.querySelector(".player-overlay .player-wrapper").append(playerEl);
    YouTubeScriptsIsLoaded = true;
}
