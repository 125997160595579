document.addEventListener('submit', async (e) => {
    e.preventDefault()
    const form = e.target
    const promise = await fetch('/api/send-mail.php', {
        method: 'POST',
        body: new FormData(form)
    })
    const response = await promise.json()
    const responseEl = form.closest('.form-inner').querySelector('.form-response')
    const responseTextEl = responseEl.querySelector('.form-response__text')
    const responseIconEl = responseEl.querySelector('.form-response__icon')
    responseTextEl.textContent = response.message;
    responseIconEl.style.display = response.code == 200 ? 'block' : 'none'
    responseEl.classList.add('show')
    if ( response.code == 200 ) {
        form.name.value = ''
        form.phone.value = ''
        form.email.value = ''

        if(form.closest('footer.footer')) {
            ym(92929342,'reachGoal','lead')
        }else {
            ym(92929342,'reachGoal','price')
        }
    }
    setTimeout(() => {
        responseEl.classList.remove('show')
    }, 2000)
})