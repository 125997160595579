import Swiper, { Navigation, Pagination } from 'swiper';
// import Swiper and modules styles
import '/node_modules/swiper/swiper.scss';
import '/node_modules/swiper/modules/navigation/navigation.scss';
import '/node_modules/swiper/modules/pagination/pagination.scss';

import {Modal} from 'bootstrap';


new Swiper(".product>.swiper", {
  // configure Swiper to use modules
  modules: [Navigation, Pagination],
  pagination: {
    el: ".product .swiper-pagination",
  }
});

document.addEventListener('click', (e) => {
    if (!e.target.closest('.btn-sw-nav')) return
    const currentSliderEl = e.target.closest('.product').querySelector('.swiper')
    e.target.closest('.btn-sw-nav').classList.contains('btn-round--next') ?
    currentSliderEl.swiper.slideNext() : 
    currentSliderEl.swiper.slidePrev()
})

document.addEventListener('click', (e) => {
  if(!e.target.closest('[data-modal-target]')) return
  const productSwiperEl = e.target.closest('[data-modal-target]')
  const modalId = productSwiperEl.dataset.modalTarget
  const modal = new Modal(modalId)
  console.log(modalId)
  const modalSwiperEl = document.querySelector(modalId).querySelector('.swiper')
  modalSwiperEl.swiper.slideTo(productSwiperEl.swiper.realIndex)
  modal.show()
})


new Swiper(".modal .swiper", {
  modules: [Navigation],
  navigation: {
    nextEl: ".btn-round--next",
    prevEl: ".btn-round--prev",
  },
});