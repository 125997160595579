// import {Modal} from 'bootstrap';
import './products'
import './video'
import './counter'
import './tel'
import './parallax'
import './from'

//Preloader
// window.onload = function() {
//     const loader = document.querySelector('.loader')
//     const banner = document.querySelector('.main')
//     setTimeout(function(){
//         banner.classList.remove('before-state')
//         // loader.addEventListener('transitionend', ()=> {
//         //     loader.style.display = 'none'
//         // })
//         // loader.classList.add('hide')
//     }, 0)
// }
window.onload = function() {
    const loader = document.querySelector('.loader')
    const banner = document.querySelector('.main')
    setTimeout(function(){
        loader.addEventListener('transitionend', ()=> {
            loader.style.display = 'none'
            banner.classList.remove('before-state')
        })
        loader.classList.add('hide')
    }, 2000)
}

//Sticky header
// document.addEventListener('scroll', () => {
//     let isVisible = false
//     const target = document.querySelector('#products')
//     const header = document.querySelector('header.header')
//     if (target.getBoundingClientRect().top < 0 && !isVisible) {
//         header.classList.add('show')
//         isVisible = true
//     }else{
//         header.classList.remove('show')
//         isVisible = false
//     }
// })




// function drawPointsAndLines(points) {
//     // Создаем канвас
//     var canvas = document.createElement('canvas');
//     canvas.width = 500;
//     canvas.height = 300;
  
//     // Добавляем канвас на страницу
//     document.body.appendChild(canvas);
  
//     // Получаем контекст канваса
//     var context = canvas.getContext('2d');
  
//     // Рисуем точки красным цветом
//     context.fillStyle = 'red';
//     for (var i = 0; i < points.length; i++) {
//       context.beginPath();
//       context.arc(points[i][0], points[i][1], 2, 0, 2 * Math.PI);
//       context.fill();
//     }
  
//     // Соединяем точки линиями синего цвета
//     context.strokeStyle = 'blue';
//     context.lineWidth = 2;
//     for (i = 0; i < (points.length - 1); i++) {
//       var x1 = points[i][0];
//       var y1 = points[i][1];
//       var x2 = points[i + 1][0];
//       var y2 = points[i + 1][1];
  
//       // Соединяем точку и следующую точку линией
//       context.moveTo(x1, y1);
//       context.lineTo(x2, y2);
//     }
//     context.stroke();
//   }
// const points = [
//     [ 6, 10],
//   [100, 100],
//   [200, 100],
//   [200, 100],
//   [250, 140],
//   [260, 240],
// ];

// drawPointsAndLines(points);