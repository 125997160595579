import Parallax from 'parallax-js'

document.addEventListener('DOMContentLoaded', () => {
    Array.from(document.querySelectorAll('.ball')).forEach(ball => {
        ball.style.animationDelay = `${randomDelay()}ms`
    })
    const  sceneEls = document.querySelectorAll('.balls-overlay');
    Array.from(sceneEls).forEach(scene => {
        new Parallax(scene);
    })
})
function randomDelay() {
    const values = [100, 200, 300, 400, 500, 600];
    return values[Math.floor(Math.random()*values.length)];
}