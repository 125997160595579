const counters = document.querySelectorAll('.subscribers__counter');
const target = document.querySelector('.subscribers');
const speed = 200;

function runCounters() {
  counters.forEach( counter => {
    const animate = () => {
       const value = +counter.dataset.counter;
       const data = +counter.innerText;
      
       const time = value / speed;
      if(data < value) {
           counter.innerText = Math.ceil(data + time);
           setTimeout(animate, 1);
         }else{
           counter.innerText = value.toLocaleString();
         }
      
    }
    
    animate();
 });
}
const counterObserver = ()=> {

    const windowHeight = window.innerHeight;
    const rec = target.getBoundingClientRect();
    const isInCenter = rec.top + rec.height - windowHeight < 0;
    if (isInCenter ) {
      runCounters()
      document.removeEventListener('scroll', counterObserver)
    }
}

document.addEventListener('scroll', counterObserver)

